<template>
	<dashboard-layout>
		<template slot="heading">
			<v-toolbar-title class="d-none d-md-block">
				Ecash Sales
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div class="d-flex align-center p-2">
				<h3 style="color: white" class="mr-5">
					{{available_wallet > 0 ? currency + ' ' + available_wallet : 'PHP 0.00'}}
				</h3>
				<ecash-withdrawal-form :currency="currency" :available_wallet="available_wallet" @submitted="requestSubmitted" @failedSubmit="submitFailed"></ecash-withdrawal-form>
			</div>
		</template>
		<template slot="content">
			<v-container>
				<v-tabs>
					<v-tab><strong>Transactions</strong></v-tab>
					<v-tab><strong>Withdrawals</strong></v-tab>
					<v-tab-item>
						<ecash-transactions :currency="currency" :wallet_total="wallet_total"></ecash-transactions>
					</v-tab-item>
					<v-tab-item>
						<ecash-withdrawals :pending_withdrawals="pending_withdrawals" :currency="currency"></ecash-withdrawals>
					</v-tab-item>
				</v-tabs>
			</v-container>
			<v-container>
				<v-snackbar
					v-model="snackbar"
					:timeout="snackbar_timeout"
				>
					{{snackbar_text}}
					<template v-slot:action="{ attrs }">
						<v-btn
							color="blue"
							text
							v-bind="attrs"
							@click="snackbar = false"
						>
							Close
						</v-btn>
					</template>
				</v-snackbar>
			</v-container>
		</template>
	</dashboard-layout>
</template>

<script>
	import { fetchWalletTotal } from '@/services/http/wallets'
	import EcashWithdrawalForm from './EcashWithdrawalForm.vue'
	import EcashTransactions from './EcashTransactions.vue'
	import EcashWithdrawals from './EcashWithdrawals.vue'
	export default {
		name: "EcashSales",
		data: () => ({
			snackbar: false,
			snackbar_text: '',
			snackbar_timeout: 5000,
			wallet_total: 0,
			canWithdraw: false,
			currency: null,
			available_wallet: null,
			pending_withdrawals: 0
		}),
		components: {
			EcashWithdrawalForm,
			EcashTransactions,
			EcashWithdrawals
		},
		mounted() {
			this.load()
		},
		methods: {
			async load() {
				var { wallet_total, currency, available_wallet, pending_withdrawals } = await fetchWalletTotal()
				this.currency = currency
				this.available_wallet = available_wallet
				this.pending_withdrawals = pending_withdrawals
				this.currency = currency
				this.wallet_total = wallet_total
			},
			requestSubmitted(){
				this.snackbar_text = 'Withdrawal request successfully submitted.'
				this.snackbar = true
				this.load()
			},
			submitFailed(){
				this.snackbar_text = 'Withdrawal request submission failed.'
				this.snackbar = true
			},

		}
	}
</script>