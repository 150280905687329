<template>
  <v-dialog
      scrollable
      v-model="dialog"
      max-width="400px"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="info"
        :disabled="available_wallet <= 0"
      >
        Withdraw
      </v-btn>
    </template>
      <v-card>
        <v-card-title>Withdrawal Form</v-card-title>
        <v-divider></v-divider>

        <v-container fluid>
          <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-select
            v-model="account_type"
            :items="account_types"
            :rules="[v => !!v || 'This field is required']"
            label="Account Type"
            required
          ></v-select>

          <v-text-field
            v-model="account_number"
            v-bind:label="accNumLabel(account_type)"
            :rules="[v => !!v || 'This field is required', v=> account_type != 'GCASH' || (/^09(\d{9})$/i).test(v) || 'Invalid Gcash Number', v=> account_type != 'MAYA' || (/^09(\d{9})$/i).test(v) || 'Invalid Maya Number']"
          ></v-text-field>

          <v-text-field
            v-model="account_name"
            label="Account Name"
            :rules="[v => !!v || 'This field is required']"
          ></v-text-field>

          <v-text-field v-model="amount"
            type="number"
            v-bind:label="amountLabel()"
            :max="available_wallet"
            :rules="[v => !!v || 'This field is required', v => v >= minimum || 'Minimum is ' + currency + minimum, v => parseFloat(available_wallet) >= parseInt(v)+fee || currency + v + ' + ' + ' ' + currency + fee + ' fee exceeds your available wallet balance '+currency+available_wallet]"

          ></v-text-field>
        </v-form>
      </v-container>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="mr-4 float-right"
          :disabled="!validForm || submitting"
          @click="submit"
        >
          Withdraw
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { fetchSupportedAccTypes, createWithdrawalReq } from '@/services/http/withdrawal_requests'
  export default {
    name: "EcashWithdrawalForm",
    data: () => ({
      loading: true,
      dialog: false,
      validForm: true,
      submitting: false,
      account_type: '',
      account_name: '',
      account_number: '',
      amount: 0.0,
      minimum: 100.0,
      fee: 10.0,
      account_types: [],
    }),
    props: ['currency', 'available_wallet'],
    created(){
      this.load()
    },
    methods: {
      async load(){
        this.account_types = await fetchSupportedAccTypes()
      },
      closeDialog() {
        this.dialog = 0
      },
      accNumLabel(acc_type){
        if(acc_type == 'GCASH') return "GCASH Number"
        if(acc_type == 'MAYA') return "MAYA Number"
        else return "Account Number"
      },
      amountLabel(){
        return "Amount("+this.currency+")"
      },
      async submit(){
        if(!this.$refs.form.validate()) return
        const yn = await this.$confirm('Are you sure you want to submit Withdrawal request?', {title: 'Confirm'})
        if (yn) {
          this.loading = true
          this.submitting = true
          try {
            var { account_type, account_name, account_number, amount } = this
            account_type = account_type.toLowerCase();
            await createWithdrawalReq({ account_type, account_name, account_number, amount })
            this.$emit("submitted")
            this.closeDialog()
          } catch(e) {
            this.$emit("failedSubmit")
          } finally {
            this.loading = false
            this.submitting = false
          }
        }
      }
    }
  }
</script>