import http from '@/services/http/http'

export async function listWallets() {
  const url = '/api/v1/wallets'
  const { data } = await http.get(url)
  return data
}

export async function fetchWalletTotal() {
  const url = `/api/v1/wallets/wallet_total`
  const res = await http.get(url)
  return res.data
}

export async function fetchCurrencies() {
  const url = `/api/v1/wallets/supported_currencies`
  const res = await http.get(url)
  return res.data
}

export async function updateCurrency(currency) {
  const url = `/api/v1/wallets/update_currency`
  const res = await http.post(url, {currency})
  return res.data
}