<template>
  <div>
    <div class="total-available-balance pt-5 pb-5" v-if="wallet_total > 0">
      <small class="success--text"><strong>Total Available Balance:
        <v-badge class="ml-3" color="green" :content="wallet_total ? currency + ' ' + wallet_total : 'PHP 0.00'" ></v-badge></strong>
      </small>
    </div>
    <div class="total-pending-balance pt-5 pb-5" v-if="total_pending_balance > 0">
      <small class="warning--text"><strong>Total Pending Balance:
        <v-badge class="ml-3" color="orange" :content="currency + ' ' + total_pending_balance"></v-badge></strong>
      </small>
    </div>
    <v-divider></v-divider>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Date
            </th>
            <th class="text-left">
              Settled At
            </th>
            <th>Reference #</th>
            <th class="text-left">
              Gross Amount
            </th>
            <th>Fee</th>
            <th>Net Earnings</th>
            <th style="min-width: 100px;">Status</th>
            <th>Customer</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="txn in ecashTransactions"
            :key="txn.id"
          >
            <td>{{ txn.created_at | formatDateAndTime }}</td>
            <td v-if="txn.settled_at">{{ txn.settled_at | formatDateAndTime }}</td>
            <td v-if="!txn.settled_at">--</td>
            <td>
              <small>{{txn.product_id}}</small>
            </td>
            <td class="green--text">
              PHP {{txn.amount}}
            </td>
            <td class="red--text">PHP {{txn.total_fees}}</td>
            <td class="green--text">PHP {{txn.net_earnings}}</td>
            <td>
                <v-badge v-if="txn.status" :content="txn.status.toUpperCase()" :color="getStatusColor(txn.status)">
                </v-badge>
            </td>
            <td>{{txn.metadata.customer_username ? 'User ' + txn.metadata.customer_username : 'Device ' + (txn.metadata.mac_address || txn.metadata.device_id)}}</td>
          </tr>
        </tbody>
        <tfoot v-if="!ecashTransactions.length">
          <tr>
            <td colspan="8" class="text-center">- No records -</td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <div v-if="!loading && ecashTransactions.length" v-observe-visibility="reachedBottom">
    </div>

    <template
      v-if="page < total_pages || loading"
      class="text-center my-6"
    >
      <div class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </template>
  </div>
</template>

<script>
  import  { mapGetters, mapActions } from 'vuex'

  export default{
    name: "EcashTransactions",
    data() {
      return {
        loading: true,
        error: null,
        page: 1,
        per_page: 15,
        total_items: 0,
        total_pages: 0,
        total_pending_balance: 0
      }
    },
    props: ['currency', 'wallet_total'],
    created() {
      this.reset()
      this.load()
    },
    destroyed() {
      this.reset()
    },
    methods: {
      reachedBottom(visible) {
        if (visible && this.page < this.total_pages && !this.loading) {
          this.page++
          this.load()
        }
      },
      async load(){
        try {
          this.loading = true
          let { page, per_page } = this
          let { total_items, total_pages, total_pending_balance } = await this.fetchEcashTransactions({ page, per_page })
          this.total_items = total_items
          this.total_pages = total_pages
          this.total_pending_balance = total_pending_balance
        } catch(e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      reset(){
        this.clearEcashTransactions()
        this.page = 1
        this.loading = true
      },
      getStatusColor(status) {
        let color = '';
        if (status === 'settled') {
          color = 'green'
        } else if (status === 'successful') {
          color = 'blue'
        } else if (status === 'failed') {
          color = 'red'
        } else if (status === 'pending') {
          color = 'orange'
        }
        return color
      },
      ...mapActions(['fetchEcashTransactions', 'clearEcashTransactions'])
    },
    computed: {
      ecashTransactions() {
        return this.allEcashTransactions || []
      },
      ...mapGetters(['allEcashTransactions'])
    },
  }
</script>