<template>
  <div>
    <div class="total-pending-balance pb-5 pt-5" v-if="pending_withdrawals > 0">
      <small class="warning--text"><strong>Total Pending Withdrawals:
        <v-badge class="ml-3" color="orange" :content="currency + ' ' + pending_withdrawals"></v-badge></strong>
      </small>
    </div>
    <v-divider></v-divider>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Date
            </th>
            <th class="text-center">
              Account
            </th>
            <th class="text-left">
              Amount
            </th>
            <th class="text-left">
              Fee
            </th>
            <th class="text-left" style="min-width: 100px;">
              Status
            </th>
            <th class="text-left">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="request in withdrawals"
            :key="request.id"
          >
            <td>{{ request.created_at | formatDateOnly }}</td>
            <td class="text-center">
              {{ request.account_type }}
              <span class="hidden-sm-and-down">|</span>
              {{request.account_number}}
            </td>
            <td class="green--text">{{request.currency}} {{request.amount}}</td>
            <td class="green--text">{{request.currency}} {{request.fee}}</td>
            <td>
              <v-badge :content="request.status.toUpperCase()" v-if="request.status" :color="getStatusColor(request.status)">
              </v-badge>
            </td>
            <td>
              <small>{{request.remarks ? request.remarks : '--'}}</small>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!withdrawals.length">
          <tr>
            <td colspan="6" class="text-center">- No records -</td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

    <div v-if="!loading && withdrawals.length" v-observe-visibility="reachedBottom">
    </div>

    <template
      v-if="page < total_pages || loading"
      class="text-center my-6"
    >
      <div class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </template>
  </div>
</template>

<script>
  import  { mapGetters, mapActions } from 'vuex'

  export default{
    name: "WithdrawalRequests",
    data() {
      return {
        loading: true,
        error: null,
        page: 1,
        per_page: 15,
        total_items: 0,
        total_pages: 0
      }
    },
    props: ['currency', 'pending_withdrawals'],
    created() {
      this.reset()
      this.load()
    },
    destroyed() {
      this.reset()
    },
    methods: {
      reachedBottom(visible) {
        if (visible && this.page < this.total_pages && !this.loading) {
          this.page++
          this.load()
        }
      },
      async load(){
        try {
          this.loading = true
          let { page, per_page } = this
          let { total_items, total_pages } = await this.fetchWithdrawals({ page, per_page })
          this.total_items = total_items
          this.total_pages = total_pages
        } catch(e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      reset(){
        this.clearWithdrawals()
        this.page = 1
        this.loading = true
      },
      getStatusColor(status) {
        let color = '';
        if (status === 'completed') {
          color = 'green'
        } else if (status === 'failed' || status === 'declined') {
          color = 'red'
        } else if (status === 'pending' || status === 'queued') {
          color = 'orange'
        }
        return color
      },
      ...mapActions(['fetchWithdrawals', 'clearWithdrawals'])
    },
    computed: {
      withdrawals() {
        return this.allWithdrawals || []
      },
      ...mapGetters(['allWithdrawals'])
    }
  }
</script>